<template>
  <Sync
  :entities="[
    'department',
    'user',
    'lead',
    'pipeline',
    'stage',
    'deal',
  ]"
  @done="done()"
  />
</template>

<script>
import Sync from '@/components/Sync'

export default {
  name: 'SaleSync',
  components: {
    Sync
  },
  methods: {
    async done () {
      await this.$api.post('sale/wizard/sync-done')
      this.$router.push({ name: 'sale/wizard' })
    }
  }
}
</script>
